<!--
程序名：问卷填写页面
功能：用户打开问卷链接对问卷进行填写
-->
<template>
  <div v-if="showPaper" class="display" v-loading="viewLoading">
    <div class="content">
      <h3>{{ title }}</h3>
      <div class="top" v-if="desc !== ''">
        {{ desc }}
      </div>
      <el-card class="box-card" v-for="(item, index) in detail" :key="index">
        <div slot="header" class="clearfix">

          <div class="questionTitle">
            <!--显示必填标识-->
            <span style="color: #f5222d;">
              <span v-if="item.quesRequired === '1'">*</span>
              <span v-else>&nbsp;</span>
            </span>
            {{ (index + 1) + '.' + item.quesDesc }}
          </div>
        </div>

        <!--单选题展示-->
        <div class="text item" v-if="item.quesType === '1'"
          v-for="(optionItem,optIndex) in (item.quesOption || '').split(',')" :key="optIndex">
          <el-radio v-model="answer[index]" :label="optionItem" style="margin: 5px;">{{ optionItem }}
          </el-radio>
        </div>

        <!--多选题展示-->
        <el-checkbox-group 
          v-if="item.quesType === '2' || item.quesType === '3'"
          v-model="checkboxs[index]"
          @change="multiTextClick($event, index)">
          <!-- 如果是可填的选项，需要预留填写答案的输入框 -->
          <div class="text item" v-for="(optionItem, optIndex) in (item.quesOption || '').split(',')" :key="optIndex">
            <el-checkbox :label="optionItem" style="margin: 5px;"></el-checkbox>
            <!-- 这里假设最后一个选项是可填，实际上应该有专门的字段来判断是否可填 -->
            <el-input
              @input="(value) => {
                e => checkboxTexts[index] = validForbid(e);
                multiTextInput(value, checkboxs[index]);
              }"
              v-if="item.quesType === '3' && optIndex === (item.quesOption || '').split(',').length - 1"
              v-model="checkboxTexts[index]" resize="none" maxlength="500" style="margin-left: 4%;width: 96%">
            </el-input>
          </div>
        </el-checkbox-group>

        <!--填空题展示-->
        <el-input v-if="item.quesType === '4' && item.idType !== '1'" type="textarea" :rows="1" v-model="answer[index]"
          maxlength="500" resize="none">
        </el-input>

        <!--身份证采集题-->
        <div v-if="item.quesType === '4' && item.idType === '1'">
          <el-form ref="idNumberObj" :model="idNumberObj" :rules="rules">
            <el-form-item prop="idNumber">
              <el-input type="textarea" :rows="1" v-model="idNumberObj.idNumber" maxlength="500" resize="none"
                @blur="answer[index] = idNumberObj.idNumber">
              </el-input>
            </el-form-item>
          </el-form>
        </div>

      </el-card>
      <el-button type="primary" style="margin: 5px;" @click="submit" :loading="submitLoading">{{ submitText }}
      </el-button>

      <div class="bottom">
        <el-link type="info">华讯调研问卷系统&nbsp;提供技术支持</el-link>
      </div>
    </div>
  </div>
  <div v-else class="display" v-loading="viewLoading">
    <h3 v-if="paperState === '不存在'">抱歉，问卷不存在</h3>
    <h3 v-if="paperState === '未发布'">抱歉，问卷未发布</h3>
    <h3 v-if="paperState === '已填写'">抱歉，问卷已填写</h3>
    <h3 v-if="paperState === '已过期'">抱歉，问卷已过期</h3>
  </div>
</template>

<script>
  import {
    getToken
  } from '@/api/user.js'
  import {
    getQuestionsByPaperId,
    saveAnswer,
    getLotteryId,
    getPaperState,
    getUserIdByEcoId,
    existPaper,
    paperIsExist,
    isFillPaper,
    paperIsExistByPaperId,
  } from '@/api/api';

  export default {
    async created() {
      // 初始化多选框的数组用于保存用户的选项
      for (let index = 0; index < 20; index++) {
        this.$set(this.checkboxs, index, []);
      }
    },

    data() {
      // 身份证的验证规则
      var validateIdNumber = (rule, value, callback) => {
        const regIdNumber =
          /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        if (regIdNumber.test(value)) {
          return callback();
        } else {
          return callback("身份证号不合规")
        }
      };

      return {
        showPaper: true,
        idNumberObj: {
          idNumber: ''
        },
        paperState: '', // 问卷状态
        checkboxTexts: [], // 保存多选框选择其他后填写的文本
        checkboxs: [], // 保存多选框的选项
        answer: [], // 用户的回答
        dialogShow: false,
        dialogTitle: '',
        dialogType: 1, //1添加 2修改
        wjId: '',
        title: '',
        desc: '',
        detail: [], // 问题列表
        startTimestamp: 0, //填写问卷开始时间戳 毫秒
        viewLoading: false, // 页面加载中效果
        submitLoading: false, //提交按钮 加载中状态
        submitText: '提交', //提交按钮文字
        rules: {
          idNumber: [{
            validator: validateIdNumber,
            trigger: 'blur'
          }],
        }
      };
    },

    async mounted() {
      this.viewLoading = true;
      // 这一步只能获取到已发布的问卷的id
      this.wjId = await this.getPaperId();
      const campaignId = sessionStorage.getItem('campaignId');
      const type = sessionStorage.getItem('type');

      if (!this.wjId && campaignId && type) {
        // 走到这里说明问卷可能存在但，是未发布的状态，需要重新获取一下问卷id
        let res = await existPaper({
          campaignId: campaignId,
          type: type
        });
        if (res.code === 200) {
          if (res.data.paperId) {
            this.wjId = res.data.paperId;
          } else {
            this.showPaper = false;
            this.paperState = '不存在';
            this.viewLoading = false;
            return;
          }
        } else if (res.code === 401) {
          this.toLogin();
        }
      }

      if (this.wjId) {
        let res = {};
        // 获取问卷状态，用来判断用户是否可以填写问卷
        res = await getPaperState({
          paperId: this.wjId
        })
        if (res.code === 200) {
          if (res.data.result === '1') {
            this.showPaper = false;
            this.paperState = '未发布';
            this.viewLoading = false;
            return;
          } else if (res.data.result === '2') {
            this.showPaper = false;
            this.paperState = '已过期';
            this.viewLoading = false;
            return;
          }
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
        // 走到这里说明问卷存在且已发布，需要判断用户是否已填写当前问卷
        let user = JSON.parse(sessionStorage.getItem('user'));
        res = await isFillPaper({
          paperId: this.wjId,
          userAccount: user.ecoAccountId
        })
        if (res.code === 200 && res.data.isFillPaper === true) {
          this.showPaper = false;
          this.paperState = '已填写';
          this.viewLoading = false;
          return;
        } else if (res.code === 401) {
          this.toLogin();
        } else if (res.code === 400) {
          this.showPaper = false;
          this.paperState = '不存在';
          this.viewLoading = false;
        }

        res = await getLotteryId({
          paperId: this.wjId
        })
        if (res.code === 200) {
          this.lotteryId = res.data.lotteryId;
        } else if (res.code === 401) {
          this.toLogin();
        }

        res = await getQuestionsByPaperId({
          paperId: this.wjId,
        })
        if (res.code === 200) {
          this.title = res.data.paper.paperTitle;
          this.desc = res.data.paper.paperDesc;
          this.detail = res.data.paper.questions;
          document.title = res.data.paper.paperTitle;
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          });
        }
      } else {
        this.showPaper = false;
      }

      // 显示加载动画
      setTimeout(() => {
        this.viewLoading = false;
      }, 300);
    },

    methods: {
      // 混合多选输入框检测到输入
      multiTextInput(newValue, checkboxs){
        if (newValue) {
          if (checkboxs.indexOf('D:其他') === -1){
            checkboxs.push('D:其他');
          }
        }else{
          let index = checkboxs.indexOf('D:其他');
          checkboxs.splice(index, 1);
        }
      },

      // 混合多选选择项改变
      multiTextClick(newValue, index){
        if (newValue.indexOf('D:其他') === -1) {
          this.checkboxTexts.splice(index, 1);
        }
      },

      // 通过活动id获取问卷id
      async getPaperId() {
        // 从uri中取数据，uri中没有就去sessionStorage中取
        let params = this.$route.query;
        if (!params.campaignId) {
          params.campaignId = sessionStorage.getItem('campaignId');
        }
        if (!params.type) {
          params.type = sessionStorage.getItem('type');
        }

        // 作为独立系统使用只有paperId,获取后直接返回
        let paperId = params.paperId;
        const CLIENT_ID_MARKET_LIST = this.$g.CLIENT_ID_MARKET_LIST;
        if (!paperId && !CLIENT_ID_MARKET_LIST.includes(sessionStorage.getItem('platform'))) {
          paperId = sessionStorage.getItem('paperId');
          // 判断这个paperId是否存在问卷
          let res = await paperIsExistByPaperId({paperId})
          if (res.code === 200 && res.data.state === '0' && res.data.exist === 'true') {
            // 独立问卷存在且已发布
            return paperId;
          }else if (res.code === 200 && res.data.state === '1' && res.data.exist === 'true') {
            // 独立问卷存在但未发布
            this.showPaper = false;
            this.paperState = '未发布';
            this.viewLoading = false;
            return null;
          }else if (res.code === 200 && res.data.state === '2' && res.data.exist === 'true') {
            // 独立问卷存在但已过期
            this.showPaper = false;
            this.paperState = '已过期';
            this.viewLoading = false;
            return null;
          }else if (res.code === 400){
            this.showPaper = false;
            this.paperState = '不存在';
            this.viewLoading = false;
            return null;
          }
        }

        // 没有问卷id、没有活动id和类型，问卷必然不存在
        if (!paperId && !params.type && !params.campaignId) {
          this.showPaper = false;
          this.paperState = '不存在';
          this.viewLoading = false;
          return null;
        }

        // 作为联合微信市场部系统使用只有campaignId和type,须获取问卷id后再返回
        let res = await paperIsExist({
          campaignId: params.campaignId
        });
        if (res.code === 200) {
          if (res.data.feedbackPaperExist && params.type === '1') {
            return res.data.feedbackPaperId;
          } else if (res.data.preSignUpPaperExist && params.type === '0') {
            return res.data.preSignUpPaperId;
          }
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.showPaper = false;
        }
      },


      // 判断是否所有必选题都有答案
      ifMustQuestionGetAnswer() {
        for (let index = 0; index < this.detail.length; index++) {
          let question = this.detail[index];
          if (question.quesRequired === '1') {
            if (this.answer[index] === undefined || this.answer[index] === '') {
              return false;
            }
          }
        }
        return true;
      },

      // 处理用户的回答为后端需要的格式
      //   参数格式为：
      //   {
      //   "userId": "10001", //用户id
      //   "ip": "192.168.xxx.1", //用户IP
      //   "paperId": "" // 问卷ID
      //   "answers": [用户的回答 {
      //       "quesId": "1", // 问题ID
      //       "userOption": "A:愿意", // 用户选择项,类型为字符串,若存在多个选项则如'A:选项A,B:选项B'
      //       "answer": "" // 用户选择其他选项后填写的答案
      //     },
      //     {
      //       "quesId": "2",
      //       "userOption": "",
      //       "answer": "xxxx"
      //     }
      //   ]
      // }
      async handleAnswer() {
        const userInfo = JSON.parse(sessionStorage.getItem('user'));
        let answerObj = {};
        // userId应该是动态的
        let res = await getUserIdByEcoId({
          userAccount: userInfo.ecoAccountId
        })
        if (res.code === 200) {
          answerObj.userId = res.data.userId;
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
        answerObj.ip = ''; // 后端获取ip
        answerObj.paperId = this.wjId;
        answerObj.answers = [];

        for (let index = 0; index < this.answer.length; index++) {
          const element = this.answer[index];
          let varObj = {
            quesId: '',
            userOption: [],
            answer: '',
          }
          varObj.quesId = this.detail[index].id;
          varObj.userOption = element ? element : '';
          varObj.answer = this.checkboxTexts[index] === undefined ? '' : this.checkboxTexts[index];

          if (this.detail[index].quesType === '4') {
            // 判断为填空题后将回答填入answer中
            varObj.answer = varObj.userOption;
            varObj.userOption = '';
          }
          answerObj.answers[index] = varObj;
        }
        return answerObj;
      },
      // 将多选框的选项汇总到用户回答中
      addCheckboxToAnswer() {
        this.checkboxs.forEach(item => {
          if (item.length !== 0) {
            let index = this.checkboxs.indexOf(item);
            this.answer[index] = this.optionsToString(item.sort());
          }
        })
      },
      //提交问卷
      async submit() {

        this.addCheckboxToAnswer();
        this.submitLoading = true;
        this.submitText = '提交中';

        if (!this.ifMustQuestionGetAnswer()) {
          this.submitLoading = false;
          this.submitText = '提交';
          this.$notify.error({
            title: '仍有必答题未回答',
          });
          return;
        }

        if (this.$refs['idNumberObj']) {
          this.$refs['idNumberObj'][0].validate(async (valid) => {
            if (valid) {
              let userAnswer = await this.handleAnswer();
              let res = await saveAnswer(userAnswer)
              if (res.code === 200) {
                this.submitLoading = false;
                this.submitText = '提交';
                // 在session中保存问卷id和抽奖活动id
                sessionStorage.setItem('paperId', this.wjId);
                sessionStorage.setItem('lotteryId', this.lotteryId);
                this.$router.push({
                  name: 'ThankYou',
                }); //跳到欢迎页
              } else if (res.code === 401) {
                this.toLogin();
              } else {
                this.submitLoading = false;
                this.submitText = '提交';
                this.$notify.error({
                  title: '错误',
                  message: res.message,
                });
              }
            } else {
              this.submitLoading = false;
              this.submitText = '提交';
              return false;
            }
          });
        } else {
          let userAnswer = await this.handleAnswer();
          let res = await saveAnswer(userAnswer)
          if (res.code === 200) {
            this.submitLoading = false;
            this.submitText = '提交';
            // 在session中保存问卷id和抽奖活动id
            sessionStorage.setItem('paperId', this.wjId);
            sessionStorage.setItem('lotteryId', this.lotteryId);
            this.$router.push({
              name: 'ThankYou',
            }); //跳到欢迎页
          } else if (res.code === 401) {
            this.toLogin();
          } else {
            this.submitLoading = false;
            this.submitText = '提交';
            this.$notify.error({
              title: '错误',
              message: res.message,
            });
          }
        }
      },

      // 将选项数组转化成字符串
      optionsToString(options) {
        let str = '';
        if (options.length === 0) {
          return str;
        }

        options.forEach(item => {
          str += item + ','
        })

        str = str.slice(0, str.length - 1);
        return str;
      },

      // token失效，需要重新获取token
      async toLogin() {
        let {
          code,
          data,
          message
        } = await getToken();
        if (code === 200) {
          sessionStorage.setItem('LocalToken', data.token);
          location.reload();
        } else {
          this.$message({
            type: 'error',
            message: message
          })
        }
      },
      // methods底部
    },
  };
</script>
<style scoped>
  .display {
    text-align: center;
    padding: 20px;
  }

  .display .top {
    color: #606266;
    padding: 0 10px 10px 10px;
    border-bottom: 3px solid #36857a;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
  }

  .display .content {
    width: 100%;
    max-width: 800px;
    display: inline-block;
    text-align: center;
  }

  .display .box-card {
    text-align: left;
    width: 100%;
    margin: 10px 0 10px 0;
  }

  .display .bottom {
    margin: 20px 10px 20px 10px;
    color: #909399;
  }

  .display a:link,
  a:visited,
  a:active {
    color: #909399;
    text-decoration: none;
  }
</style>