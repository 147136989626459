/**
 * 程序名：需要认证的接口
 */
import { getTokenByCodeApi, getUserInfoApi, logoutApi, getTokenApi, getMarketTokenApi, getEccomTokenFromMarketApi } from './api'
import g from '@/api/globalVariable.js'

// 使用code获取api访问认证token
export function getTokenByCode(code) {
  let parameter = {
    code: code,
    redirectUri: g.AUTH_INDEX
  };
  return getTokenByCodeApi(parameter)
}

// 获取当前登录用户信息
export function getUserInfo(token) {
  return getUserInfoApi('Bearer ' + token)
}

// 登出
export function logout(token) {
  return logoutApi('Bearer ' + token)
}

// 获取本系统的认证token
export function getToken() {
  return getTokenApi()
}

// 获取市场活动的token
export function getMarketToken() {
  return getMarketTokenApi()
}

// 通过市场活动获取认证系统token
export function getEccomTokenFromMarket(parameter, token) {
  return getEccomTokenFromMarketApi(parameter, `JWT ${token}`)
}
